@import '~antd/dist/antd.css';

.App {
  font-size: 13px;
}

.App-link {
  color: #09d3ac;
}

.editable-cell {
  position: relative;
}

.editable-row:hover .editable-cell-value-wrap {
  box-shadow: inset 0px 0px 0px 1px #d9d9d9;  /* To make hovering feel less jumpy */
  border-radius: 4px;
}

.green-background {
  background-color: #f6ffed;
}

.cdf-project {
  position: fixed;
  left: 5px;
  font-weight: bold;
  zoom: 150%;
  z-index: 9999;
}